import classNames from 'classnames';
import Link from 'next/link';
import { Trans, useTranslation } from 'next-i18next';
import { useState } from 'react';

export default function FAQ({ size = 'base', shadowButton = false }: { size?: 'base' | 'sm'; shadowButton: boolean }) {
  const [activeFaq, setActiveFaq] = useState('');
  const { t } = useTranslation('components/faq');

  const items = t('faq', { returnObjects: true });

  const handleKeyDown = (event: any, key: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setActiveFaq(key);
    }
  };

  return (
    <div className='flex items-center justify-center'>
      <div className='w-full max-w-[1400px] p-5 lg:pb-40'>
        <div className='flex flex-col justify-between gap-10 ipad:flex-row'>
          <div className='flex flex-col gap-10'>
            <h2 className={classNames('max-w-[578px] font-bold leading-[110%] text-white', size === 'sm' && 'text-8xl', size === 'base' && 'text-8-5xl')}>{t('ask')}</h2>
            <p className='max-w-[559px] text-base font-medium leading-[140%] text-[#53535F]'>{t('ask_description')}</p>
            <div className='flex w-full flex-col gap-4 lg:flex-row'>
              <Link className={classNames('w-full rounded-lg bg-green-300 p-2.5 py-4 text-center text-sm font-bold uppercase text-black transition-opacity duration-200 hover:opacity-80 hover:shadow-xl', shadowButton && 'shadow-[0px_0px_73.5px_0px_#AEF55C61]')} href='/trial'>
                {t('ask_button_affiliate')}
              </Link>
              <Link className='flex w-full items-center justify-center rounded-lg bg-gray-300 p-2.5 py-4 text-center text-sm font-bold uppercase text-white transition-opacity duration-200 hover:opacity-80 hover:shadow-xl' href='/support/0'>
                {t('faq_button')}
              </Link>
            </div>
          </div>
          <div>
            <div className='flex w-full flex-col items-start gap-2.5 lg:max-w-[666px]'>
              {Object.entries(items)
                .slice(0, 6)
                .map(([key, value]) => (
                  <div key={key} onKeyDown={(event) => handleKeyDown(event, key)} onClick={() => setActiveFaq(key !== activeFaq ? key : '')} className={classNames('flex w-full cursor-pointer flex-col items-start rounded-[0.625rem] border px-[1.625rem] py-[0.8125rem] xl:min-w-[666px]', activeFaq !== key && 'border-transparent bg-[#080809] transition-colors hover:border-green-300', activeFaq === key && 'gap-5 border-green-300 bg-[#17171a]')}>
                    <div className='flex w-full items-center justify-between'>
                      <div className='w-full text-lg font-semibold uppercase leading-[140%] text-white'>{value.question}</div>
                      <div className='flex flex-col items-center justify-between'>
                        {activeFaq === key && (
                          <svg width={12} height={4} viewBox='0 0 12 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M0.5 0.389648H11.5V3.38965H0.5V0.389648Z' fill='#D9D9D9' />
                          </svg>
                        )}
                        {activeFaq !== key && (
                          <svg width={12} height={12} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M4.5 0.389648H7.5V11.3896H4.5V0.389648Z' fill='#5A5A65' />
                            <path d='M0.5 4.38965H11.5V7.38965H0.5V4.38965Z' fill='#5A5A65' />
                          </svg>
                        )}
                      </div>
                    </div>
                    <div className={`scrollbar flex w-full gap-2 overflow-auto p-2 ${activeFaq !== key && 'hidden'}`}>
                      <div className={classNames(`flex-1 ${activeFaq === key ? 'max-h-52 min-h-44' : 'h-0'}`)}>
                        <div className={classNames('overflow-hidden font-medium leading-10 text-zinc-300 transition')}>
                          <p className='pr-4'>
                            <Trans i18nKey={value.answer} components={{ br: <br />, strong: <strong className='text-green-300' /> }} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
